import React, { useEffect, useRef, useState } from "react";
import guide from "../../assets/images/guidelines.svg";
import checkStep from "../../assets/images/radio_button_checked.svg";
import warning from "../../assets/images/warning.svg";
import tick from "../../assets/images/tick.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import uploadIcon from "../../assets/images/upload.svg";
import * as XLSX from "xlsx";
import ReactLoadingOverlay from "react-loading-overlay";
import ClipLoader from "react-spinners/ClipLoader";
import {
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { FnAuthenticatedRequest } from "../../Utility/Utils";
import StorageHelper from "../../Utility/StorageHelper";
import { toast } from "react-toastify";
import GuideLines from "./GuideLines";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
    border: "none",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
    },
  },
  tableContainer: {
    borderSpacing: "15px",
  },
  tableRow: {
    borderBottom: "none",
  },
  bold: {
    fontWeight: "bold",
  },
  editIcon: {
    color: "gray",
  },
}));

export default function BulkUploads(props) {
  const classes = useStyles();
  const { handleBack, bulkUpload, users } = props;
  const [uploadedData, setUploadedData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [isTableView, setIsTableView] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [sortByError, setSortByError] = useState(false);
  const [unSortedData, setUnSortedData] = useState([]);
  const [erroedEntriesCount, setErroedEntriesCount] = useState(0);
  const [rightEntriesCount, setRightEntriesCount] = useState(
    uploadedData?.length
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleRemoveFile = () => {
    setFileName("");
    setUploadedData([]);
    // Reset file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (isValidated && uploadedData) {
      let trueCounter = 0;
      let falseCounter = 0;

      uploadedData.forEach((user: any) => {
        if (user && typeof user.isValid === "boolean") {
          if (user.isValid === true) {
            trueCounter++;
          } else {
            falseCounter++;
          }
        }
      });

      setRightEntriesCount(trueCounter);
      setErroedEntriesCount(falseCounter);
    }
  }, [uploadedData, isValidated]);

  const handleSortByError = () => {
    try {
      if (isValidated) {
        let sortedData;
        if (sortByError) {
          setUploadedData(unSortedData);
        } else {
          sortedData = [...uploadedData].filter(
            (a: any) => a.isValid === false
          );
          setUploadedData(sortedData);
        }
        setSortByError(!sortByError);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const excelDateToJSDate = (inputDate) => {
    const date = new Date((inputDate - (25567 + 1)) * 86400 * 1000);
    date.setHours(0, 0, 0, 0);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const convertValuesToString = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = String(obj[key]);
      return acc;
    }, {});
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    if (file?.name !== fileName) {
      const reader = new FileReader();
      reader.onload = function (e) {
        try {
          const contents = e.target?.result as string;
          const workbook = XLSX.read(contents, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const data: any = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );

          const expectedHeaders = [
            "Mobile Number",
            "Role",
            "Reporting Manager Mobile Number",
            "Partner Name",
            "Zone (Mandatory for ZBM, RBM,TBM,KA)",
            "State (Mandatory for KA,RBM,TBM)",
            "District (Mandatory for KA)",
            "Territory (Mandatory for TBM,KA)",
            "Joining Date (DD-MM-YYYY)",
            "Test User (Yes or No)(In case of empty by default No will be selected)",
          ];

          const uploadedHeaders = Object.keys(data[0]);
          const matchingHeadersCount = expectedHeaders.reduce(
            (count, header) => {
              if (uploadedHeaders.includes(header)) {
                return count + 1;
              }
              return count;
            },
            0
          );
          const arrUploadedData: any = data.map((v: any) => {
            function getValueOrEmptyString(value) {
              return value !== undefined && value !== null ? value : "";
            }
            let userObject = {
              name: getValueOrEmptyString(v["Name"]),
              mobile: getValueOrEmptyString(v["Mobile Number"]),
              roleName: getValueOrEmptyString(v["Role"]),
              reportingManager: getValueOrEmptyString(
                v["Reporting Manager Mobile Number"]
              ),
              partnerName: getValueOrEmptyString(v["Partner Name"]),
              zone: getValueOrEmptyString(
                v["Zone (Mandatory for ZBM, RBM,TBM,KA)"]
              ),
              state: getValueOrEmptyString(
                v["State (Mandatory for KA,RBM,TBM)"]
              ),
              territory: getValueOrEmptyString(
                v["Territory (Mandatory for TBM,KA)"]
              ),
              district: getValueOrEmptyString(v["District (Mandatory for KA)"]),
              joinedDate: excelDateToJSDate(v["Joining Date (DD-MM-YYYY)"]),
              isTestUser: getValueOrEmptyString(
                v[
                  "Test User (Yes or No)(In case of empty by default No will be selected)"
                ]
              ),
            };
            return convertValuesToString(userObject);
          });
          if (arrUploadedData?.length > 0 && matchingHeadersCount > 4) {
            setUploadedData(arrUploadedData);
            setFileName(file?.name);
          } else {
            const message = "Invalid file. Please try again !";
            toast.error(message, {
              position: toast.POSITION.TOP_CENTER,
              style: {
                backgroundColor: "#ff4d4d",
                color: "white",
              },
              autoClose: 2000,
              toastId: "customErrorToast",
            });
            const fileInput = document.getElementById("fileInput");
            if (fileInput !== null && fileInput !== undefined) {
              (fileInput as HTMLInputElement).value = "";
            }
          }
        } catch (error) {
          console.error("Error parsing file:", error);
        }
      };

      reader.onerror = function () {
        console.error("Failed to read the file.");
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleSubmit = async (data) => {
    try {
      if (!Array.isArray(data)) {
        throw new Error("Data is not an array");
      }
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData.jwtToken,
        userId: authData.userId,
      };

      const inputData = data
        .filter((user) => user && user.isValid)
        .map((user) => {
          const territoryMap = {
            zone:
              user.zone?.length > 0
                ? user.zone
                    .toUpperCase()
                    .split(",")
                    .map((zone) => zone.trim())
                    .map((zone) => ({ zoneId: "", zoneName: zone }))
                : [],
            state:
              user.state?.length > 0
                ? user.state
                    .toUpperCase()
                    .split(",")
                    .map((state) => state.trim())
                    .map((state) => ({ stateId: "", stateName: state }))
                : [],
            territory:
              user.territory?.length > 0
                ? user.territory
                    .toUpperCase()
                    .split(",")
                    .map((territory) => territory.trim())
                    .map((territory) => ({
                      territoryId: "",
                      territoryName: territory,
                    }))
                : [],
            district:
              user.district?.length > 0
                ? user.district
                    .toUpperCase()
                    .split(",")
                    .map((district) => district.trim())
                    .map((district) => ({
                      districtId: "",
                      districtName: district,
                    }))
                : [],
          };
          return {
            name: user.name,
            mobile: user.mobile,
            roleName: user.role,
            partnerName: user.partnerName,
            altMobile: "",
            altMobileLogin: false,
            email: "",
            territoryMap: territoryMap,
            joinedDate: user.joinedDate,
            createdBy: authData.email,
            reportingManager: user.reportingManager,
            isTestUser: user.isTestUser?.toLowerCase() === "yes" ? true : false,
            appCode: "carbon-bo",
            vertical: "carbon",
          };
        });

      const payload = inputData;
      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/AddKaUserMultiple",
        authHeader,
        payload
      );
      if (response?.success) {
        const entryCount = payload?.length;
        const message = `✓ ${entryCount} Entry Import${
          entryCount !== 1 ? "s" : ""
        } Successful`;
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER,
          style: {
            backgroundColor: "#258750",
            color: "white",
          },
          autoClose: 3000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.success(response.error, {
          position: toast.POSITION.TOP_CENTER,
          style: {
            backgroundColor: "#ff4d4d",
            color: "white",
          },
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleValidate = async () => {
    try {
      if (uploadedData?.length !== 0) {
        setIsLoading(true);
        let authData = JSON.parse(StorageHelper.get("auth") || "{}");
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const payload = uploadedData;
        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/ValidateCarbonUserMultiple",
          authHeader,
          payload
        );
        if (response?.success) {
          setIsLoading(false);
          setUploadedData(response?.data);
          setUnSortedData(response?.data);
          setIsValidated(true);
        } else {
          setIsLoading(false);
          toast.success(response.error, {
            position: toast.POSITION.TOP_CENTER,
            style: {
              backgroundColor: "#ff4d4d",
              color: "white",
            },
            autoClose: 3000,
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleNext = () => {
    try {
      if (uploadedData?.length !== 0) {
        handleValidate();
        setIsTableView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrev = () => {
    try {
      if (uploadedData?.length !== 0) {
        setIsTableView(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleGuide = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const generateErrorFilename = () => {
    try {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}${month}${day}`;
      const filename = `ErrorData_Bulk_Upload_Ka_${formattedDate}`;
      return filename;
    } catch (error) {
      console.error(error);
    }
  };

  const downloadExcel = (jsonObject, fileName) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(jsonObject);

      // Get the range of the worksheet
      const range = XLSX.utils.decode_range(
        worksheet["!ref"] ? worksheet["!ref"] : ""
      );

      // Iterate through the first row (headers)
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        if (!worksheet[cellAddress]) continue;
        worksheet[cellAddress].s = {
          font: { bold: true },
        };
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadErrorData = (data) => {
    try {
      const errorData = data
        .filter((user) => user && !user.isValid)
        .map((user) => {
          return {
            Name: user?.name,
            "Mobile Number": user?.mobile,
            Role: user?.role,
            "Reporting Manager Mobile Number": user?.reportingManager,
            "Partner Name": user?.partnerName,
            "Zone (Mandatory for ZBM, RBM,TBM,KA)": user?.zone,
            "State (Mandatory for KA,RBM,TBM)": user?.state,
            "Territory (Mandatory for TBM,KA)": user?.territory,
            "District (Mandatory for KA)": user?.district,
            "Joining Date (DD-MM-YYYY)": user?.joinedDate,
            "Test User (Yes or No)(In case of empty by default No will be selected)":
              user?.isTestUser,
          };
        });
      const fileName = generateErrorFilename();
      console.log(errorData);
      // downloadExcel(errorData, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  const fnJoinedDate = (joinedDate) => {
    const parsedDate = Date.parse(joinedDate);
    if (isNaN(parsedDate)) {
      return <div>Invalid date</div>;
    }
    const date = new Date(parsedDate);
    date.setUTCHours(0, 0, 0, 0);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return <div>{formattedDate}</div>;
  };

  return (
    <>
      {isLoading === false ? (
        <div className="px-5">
          <div className="block sm:flex items-center">
            <ArrowBackIcon
              className="cursor-pointer transition-all hover:text-[#009CA7] active:text-[#007a80]"
              onClick={handleBack}
            />
            <h1 className="text-2xl mt-1 font-bold px-3 py-3">Bulk Upload</h1>
            <img
              src={guide}
              className="ml-auto mr-12 cursor-pointer"
              alt="guide"
              onClick={toggleGuide}
            />
            <GuideLines isOpen={isModalOpen} onClose={toggleGuide} />
          </div>
          <hr className="mt-1 border border-gray-200" />
          <div className="mt-2">
            <div className="flex justify-center items-center">
              <div className="flex flex-col items-center">
                <p
                  className={`flex w-6 h-6 rounded-full items-center justify-center mr-0 bg-[#009CA7] text-white`}
                >
                  {isTableView === false ? (
                    <>1</>
                  ) : (
                    <img src={checkStep} alt="step1" />
                  )}
                </p>
                <p>Upload</p>
              </div>
              <hr
                className={`w-56 border-2 border-t ${
                  !isTableView ? `border-gray-300` : `border-[#009CA7]`
                }  -mt-6 -mx-3.5`}
              />
              <div className="flex flex-col items-center">
                <p
                  className={`flex w-6 h-6 rounded-full items-center justify-center mr-0 ${
                    isTableView
                      ? `bg-[#009CA7] text-white`
                      : `bg-gray-300 text-gray-400`
                  } `}
                >
                  2
                </p>
                <p>View</p>
              </div>
            </div>
          </div>
          {isTableView === true ? (
            <div>
              <div className="flex pb-2">
                <p className="text-sm py-1">
                  Showing {uploadedData?.length} entries
                </p>
                <div className="ml-auto -my-1">
                  <FormControlLabel
                    style={{
                      color: "#666666",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                    }}
                    control={
                      <Switch
                        color="primary"
                        checked={sortByError}
                        onChange={handleSortByError}
                      />
                    }
                    label=" Only show rows with errors"
                  />
                </div>
              </div>
              <div className="bg-white p-3">
                <div className="flex gap-2">
                  {uploadedData?.length !== erroedEntriesCount && (
                    <div className="font-bold py-3">
                      <span className="flex flex-row">
                        <img src={tick} alt="tick" />{" "}
                        <p className="text-[#009CA7] px-1">
                          {isValidated === true
                            ? rightEntriesCount
                            : uploadedData?.length}{" "}
                          {rightEntriesCount === 1 ? "Entry" : "Entries"}
                        </p>
                        are ready to be imported
                      </span>
                    </div>
                  )}

                  {isValidated && (
                    <>
                      {uploadedData?.length !== erroedEntriesCount && (
                        <span className="h-7 border-l border-2 border-gray-300 mx-1 mt-2"></span>
                      )}
                      {erroedEntriesCount > 0 && (
                        <div className="font-bold py-3">
                          <span className="flex flex-row">
                            <img src={warning} alt="tick" />{" "}
                            <p className="text-[#FF0000] px-1">
                              {erroedEntriesCount}{" "}
                              {erroedEntriesCount === 1 ? "Entry" : "Entries"}
                            </p>
                            {uploadedData?.length === erroedEntriesCount
                              ? `contains error, fix error before you import.`
                              : `will be skipped if you import.`}
                            {/* will be skipped if you import.{" "} */}
                            {uploadedData?.length !== erroedEntriesCount && (
                              <span
                                className="text-[#009CA7] px-1 cursor-pointer underline"
                                onClick={() => {
                                  downloadErrorData(uploadedData);
                                }}
                              >
                                Download
                              </span>
                            )}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <hr className="border border-gray-300" />
                <TableContainer component={Paper} className="mt-3 h-80">
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="bg-[#E5F5F6] text-nowrap">
                        <TableCell className={classes.bold}>Name</TableCell>
                        <TableCell className={classes.bold}>Mobile</TableCell>
                        <TableCell className={classes.bold}>Role</TableCell>
                        <TableCell className={classes.bold}>
                          Reporting Manager
                        </TableCell>
                        <TableCell className={classes.bold}>
                          Partner Name
                        </TableCell>
                        <TableCell className={classes.bold}>Zone</TableCell>
                        <TableCell className={classes.bold}>State</TableCell>
                        <TableCell className={classes.bold}>District</TableCell>
                        <TableCell className={classes.bold}>
                          Territory
                        </TableCell>
                        <TableCell className={classes.bold}>
                          Joining Date
                        </TableCell>
                        <TableCell className={classes.bold}>
                          Test User
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uploadedData?.map((user: any, index) => {
                        return (
                          <TableRow
                            key={index}
                            className="border-0 text-nowrap"
                          >
                            <TableCell
                              className={`${
                                user?.validationObject?.name === false &&
                                `bg-red-400`
                              }`}
                            >
                              {user?.name}
                            </TableCell>
                            <TableCell
                              className={`${
                                user?.validationObject?.mobile === false &&
                                `bg-red-400`
                              }`}
                            >
                              {user?.mobile}
                            </TableCell>
                            <TableCell
                              className={`${
                                user?.validationObject?.role === false &&
                                `bg-red-400`
                              }`}
                            >
                              {user?.role}
                            </TableCell>
                            <TableCell
                              className={`${
                                user?.validationObject?.reportingManager ===
                                  false && `bg-red-400`
                              }`}
                            >
                              {user?.reportingManager}
                            </TableCell>
                            <TableCell
                              className={`${
                                user?.validationObject?.partnerName === false &&
                                `bg-red-400`
                              }`}
                            >
                              {user?.partnerName}
                            </TableCell>
                            <TableCell
                              className={`${
                                user?.validationObject?.zone === false &&
                                `bg-red-400`
                              }`}
                            >
                              {user?.zone}
                            </TableCell>
                            <TableCell
                              className={`${
                                user?.validationObject?.state === false &&
                                `bg-red-400`
                              }`}
                            >
                              {" "}
                              {user?.state}
                            </TableCell>
                            <TableCell
                              className={`${
                                user?.validationObject?.district === false &&
                                `bg-red-400`
                              }`}
                            >
                              {" "}
                              {user?.district}
                            </TableCell>
                            <TableCell
                              className={`${
                                user?.validationObject?.territory === false &&
                                `bg-red-400`
                              }`}
                            >
                              {" "}
                              {user?.territory}
                            </TableCell>

                            <TableCell
                              className={`${
                                user?.validationObject?.joinedDate === false &&
                                `bg-red-400`
                              }`}
                            >
                              {" "}
                              {fnJoinedDate(user?.joinedDate)}
                            </TableCell>
                            <TableCell
                              className={`${
                                user?.validationObject?.isTestUser === false &&
                                `bg-red-400`
                              }`}
                            >
                              {" "}
                              {user?.isTestUser}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          ) : (
            <>
              <div className="mt-10">
                <div className="flex justify-center mt-0">
                  <div
                    className={`border-2 border-dashed border-opacity-50 border-gray-400 ${
                      uploadedData?.length === 0 ? `bg-white` : `bg-green-300`
                    } flex flex-col justify-center items-center px-28 py-12`}
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      id="fileInput"
                      accept=".csv, .xlsx"
                      className="hidden"
                      onChange={handleFileUpload}
                    />
                    <img
                      src={uploadIcon}
                      alt="Bulk Upload Img"
                      className="cursor-pointer"
                      onClick={() =>
                        document.getElementById("fileInput")?.click()
                      }
                    />
                    {fileName !== "" && (
                      <div>
                        <span className="text-wrap w-2">{fileName}</span>
                        <span
                          className="cursor-pointer text-2xl text-red-500 ml-2"
                          onClick={handleRemoveFile}
                        >
                          x
                        </span>
                      </div>
                    )}

                    <p
                      className="text-[#009CA7] cursor-pointer font-semibold mt-4 text-base"
                      onClick={() =>
                        document.getElementById("fileInput")?.click()
                      }
                    >
                      {uploadedData?.length === 0
                        ? "Upload File"
                        : "Replace File"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center mt-7">
                <h1 className="text-base">
                  Use csv format, or{" "}
                  <a
                    // href="/soil_sampling_template.xlsx"
                    href="/Bulk upload template.xlsx"
                    className="text-[#009CA7] font-bold"
                    download
                  >
                    Download Template
                  </a>
                </h1>
              </div>
            </>
          )}

          <div className="flex fixed bottom-2 left-16 right-0 justify-center items-center gap-10 sm:gap-96">
            <button
              className="flex px-6 py-1.5 border-2 border-grey-300 rounded-md bg-white mr-4"
              onClick={handleBack}
            >
              Cancel
            </button>
            {isTableView ? (
              <div className="flex gap-3">
                <button
                  className={`flex px-8 py-1.5 border-2 border-grey-300 rounded-md text-dark bg-white`}
                  onClick={handlePrev}
                >
                  Prev
                </button>
                <button
                  className={`flex px-8 py-1.5 border-2 border-grey-300 rounded-md text-white ${
                    (uploadedData?.length === 0 ||
                      uploadedData?.length === erroedEntriesCount) &&
                    isValidated
                      ? `bg-gray-400`
                      : `bg-[#009CA7]`
                  } `}
                  disabled={
                    uploadedData?.length === erroedEntriesCount && isValidated
                  }
                  onClick={() => {
                    if (isValidated) {
                      handleSubmit(uploadedData);
                    } else {
                      handleValidate();
                    }
                  }}
                >
                  {isValidated === true ? `Import` : `Validate`}
                </button>
              </div>
            ) : (
              <button
                className={`flex px-8 py-1.5 border-2 border-grey-300 rounded-md text-white ${
                  uploadedData?.length === 0 ? `bg-gray-400` : `bg-[#009CA7]`
                } `}
                onClick={handleNext}
              >
                Next
              </button>
            )}
          </div>
        </div>
      ) : (
        <ReactLoadingOverlay
          classNamePrefix="growindigo_"
          active={true}
          spinner={<ClipLoader color="#27878E" />}
          text={""}
        ></ReactLoadingOverlay>
      )}
    </>
  );
}
